<template>
    <multiselect
        ref="topicsMultiselect"
        :value="value"
        class="topics-multiselect"
        track-by="id"
        label="name"
        open-direction="bottom"
        :options="topicsList"
        :show-labels="false"
        :multiple="true"
        :searchable="true"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="false"
        :preserve-search="true"
        :options-limit="100"
        :max-height="600"
        :hide-selected="true"
        v-bind="$attrs"
        @search-change="asyncSearchTopics"
        @close="$refs.topicsMultiselect.search = ''"
        @input="(value) => $emit('input', value)"
    >
        <template v-if="showSuggestNewTopic && shouldAllowSuggestTopics" slot="beforeList">
            <button class="btn suggest-new-topic" type="button" @click="$modal.show('suggest-topic')">
                Suggest new topic
            </button>
        </template>
        <template slot="tag" slot-scope="{ option }">
            <span class="topic-tag" :class="{ 'is-pending' : option.status == TOPIC_STATUS.PENDING || option.is_pending == TOPIC_STATUS.PENDING }">
                <span>{{ option.name }}</span>
                <span v-if="option.status != TOPIC_STATUS.PENDING && option.is_pending != TOPIC_STATUS.PENDING" class="topic-remove" @click="$emit('remove-topic', option)">
                    <img src="@assets/img/icons/x.svg" alt="X" width="7px">
                </span>
            </span>
        </template>
        <span slot="noResult" class="no-results">Can't find the topic you're looking for? Suggest a new one! The Memo'd team is always looking for unique and important Memo Topics, and will review all new contributions.</span>
    </multiselect>
</template>

<script>
import debounce from "lodash/debounce";
import { TOPIC_STATUS } from "@/utils/constants";

export default {
    name: "TopicsMultiselect",
    props: {
        value: {
            type: Array,
            required: true
        },
        showSuggestNewTopic: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: false,
            topicsList: [],
            TOPIC_STATUS
        };
    },
    computed: {
        shouldAllowSuggestTopics() {
            // eslint-disable-next-line no-prototype-builtins
            const topicsPendingForApproval = this.value.filter(topic => topic.hasOwnProperty("status") && topic.status == this.TOPIC_STATUS.PENDING)

            return Boolean(topicsPendingForApproval.length < 3);
        }
    },
    created() {
        this.asyncSearchTopics = debounce(this.searchTopics, 500);
        this.searchTopics("");
    },
    methods: {
        searchTopics(topicToSearch) {
            axiosV2.get(`/topics${topicToSearch == "" ? "" : `?q=(name:${topicToSearch}%)`}`).then(({ data }) => {
                this.topicsList = data;
            }).catch((error) => {
                console.log(error.response.data.message);

            })
        }
    }
}
</script>

<style lang="scss" scoped>
.topics-multiselect {
    /deep/ .multiselect__select {
        display: none;
    }

    /deep/ .multiselect__tags {
        overflow: auto;
        height: initial !important;
        padding-right: 0;

        .multiselect__tags-wrap {
            display: flex;
            flex-wrap: wrap !important;
            flex-shrink: 1 !important;

            .topic-tag {
                height: 30px;
                background-color: #2C2C2E;
                padding: 0 10px;
                margin-right: 10px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                font-size: 18px;
                color: #979797;
                margin-bottom: 5px;

                .topic-remove {
                    width: 20px;
                    height: 20px;
                    background-color: #3A3A3C;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    margin-left: 5px;
                    cursor: pointer;
                }

                &.is-pending {
                    border: 1px solid #05A88F;
                }
            }
        }

        .multiselect__input {
            min-width: 100px;
            height: 30px;
            align-self: flex-end;
            margin-bottom: 5px;

            &::placeholder {
                color: transparent !important;
            }

        }

    }

    .no-results {
        white-space: normal;
    }

    /deep/ .multiselect__content-wrapper {
        .multiselect__content {
            margin-top: 20px;

            .suggest-new-topic {
                border: 1px solid white;
                padding: 6px 13px;
                font-size: 14px;
                color: white;
                margin-left: 12px;
                margin-bottom: 8px;
            }
        }
    }
}
</style>
